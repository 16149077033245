import React from 'react';
import './Footer.css'; // Import the CSS file for the Footer component

import logo from '../../Pictures/logo/logo2.png';
import mapIcon from '../../Pictures/icons/map.png';
import facebookIcon from '../../Pictures/icons/facebook.png';
import gmailIcon from '../../Pictures/icons/gmail.png';
import whatsappIcon from '../../Pictures/icons/whatsapp.png';

const Footer = () => {
  return (
    <footer>
      <img src={logo} id="footer-logo" alt="logo" />
      <div className="contact">
        <ul>
          <li>
            <a href="https://www.google.com/maps/place/Apartmani+Sun%C4%8Dica/@45.1537444,14.7189386,17z/data=!3m1!4b1!4m6!3m5!1s0x47637bdc65f8b315:0x7405bb80d2a3bb45!8m2!3d45.1537406!4d14.7215135!16s%2Fg%2F11cnn5965c?entry=ttu">
              <img src={mapIcon} alt="Maps" />
              Matkino 6, Selce, Croatia
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/apartmanisuncicaselce">
              <img src={facebookIcon} alt="Facebook" />
              Apartmani Sunčica
            </a>
          </li>
          <li>
            <a href="mailto:svetka.krsul@gmail.com">
              <img src={gmailIcon} alt="Gmail" />
              svetka.krsul@gmail.com
            </a>
          </li>
          <li>
            <a href="https://wa.me/385915122107">
              <img src={whatsappIcon} alt="WhatsApp" />
              +385 91 512 2107
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
