import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, currentImage, images, onClose, onNext, onPrev }) => {
  if (!isOpen) return null;

  return (
    <div className="image-modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <img src={currentImage} alt="Fullscreen" className="modal-image" />
        <button className="nav-button prev" onClick={onPrev}>&lt;</button>
        <button className="nav-button next" onClick={onNext}>&gt;</button>
      </div>
    </div>
  );
};

export default Modal;
