// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// Import components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import GoToTop from './components/GoToTop';
// Import pages
import Home from './Pages/Home/Home';
import Apartment1 from './Pages/Apartment 1/Apartment1';
import Apartment2 from './Pages/Apartment 2/Apartment2';
import Apartment3 from './Pages/Apartment 3/Apartment3';
import Apartment4 from './Pages/Apartment 4/Apartment4';
//import Recaptcha from './components/Recaptcha/Recaptcha'; // Adjust path as needed

const App = () => {
  const [showNavLinks, setShowNavLinks] = useState(false);

  const toggleNavLinks = () => {
    setShowNavLinks(prev => !prev);
  };

  const handleScroll = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 60;
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };


  return (
    <Router>
      <GoToTop />
      <div className="App">
        <Navbar showNavLinks={showNavLinks} toggleNavLinks={toggleNavLinks} handleScroll={handleScroll}/>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/apartment1" element={<Apartment1 />} />
            <Route path="/apartment2" element={<Apartment2 />} />
            <Route path="/apartment3" element={<Apartment3 />} />
            <Route path="/apartment4" element={<Apartment4 />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
