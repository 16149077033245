import React, { useState } from 'react';
import './Apartment3.css';
import Slider from 'react-slick';
import Modal from '../../components/Modal/Modal'; // Ensure the correct path

import Image1 from '../../Pictures/Apartments/Apartment 3/01-APP3.jpg';
import Image2 from '../../Pictures/Apartments/Apartment 3/03-APP3.jpg';
import Image3 from '../../Pictures/Apartments/Apartment 3/06-APP3.jpg';
import Image4 from '../../Pictures/Apartments/Apartment 3/08-APP3.jpg';
import Image5 from '../../Pictures/Apartments/Apartment 3/09-APP3.jpg';
import Image6 from '../../Pictures/Apartments/Apartment 3/10-APP3.jpg';
import Image7 from '../../Pictures/Apartments/Apartment 3/11-APP3.jpg';
import Image8 from '../../Pictures/Apartments/Apartment 3/13-APP3.jpg';
import Image9 from '../../Pictures/Apartments/Apartment 3/16-APP3.jpg';
import Image10 from '../../Pictures/Apartments/Apartment 3/18-APP3.jpg';
import Image11 from '../../Pictures/Apartments/Apartment 3/20-APP3.jpg';
import Image12 from '../../Pictures/Apartments/Apartment 3/21-APP3.jpg';

import AC from '../../Pictures/icons/air-conditioner-icon.png';
import Coffee from '../../Pictures/icons/coffee-machine-icon.png';
//import Dishwasher from '../../Pictures/icons/dishwasher-icon.png';
import DoubleBed from '../../Pictures/icons/double-bed-icon.png';
import Kitchen from '../../Pictures/icons/fridge-icon.png';
import TV from '../../Pictures/icons/led-television-icon.png';
import Microwave from '../../Pictures/icons/microwave-icon.png';
import NoSmoking from '../../Pictures/icons/no-smoking-icon.png';
import Parking from '../../Pictures/icons/parking-location-icon.png';
import WiFi from '../../Pictures/icons/wifi-icon.png';

const Apartment3 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12
  ];

  const openModal = (index) => {
    setCurrentIndex(index);
    setCurrentImage(images[index]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goNext = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
    setCurrentImage(images[nextIndex]);
  };

  const goPrev = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
    setCurrentImage(images[prevIndex]);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    swipe: false, // Disable swipe functionality
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: '0%',
          swipe: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0%',
          swipe: true,
        },
      },
    ],
  };

  return (
    <div className="apartment3-page">
      <div className="about">
        <h1><em>Apartment 3</em></h1>
        <h2>1 Bedroom | 1 Bathroom | 48 m<sup>2</sup></h2><br/>
        <p>Discover your serene retreat in this elegant one-bedroom apartment, where tranquility and style harmonize effortlessly.<br/> 
          Enjoy sweeping sea views from your private balcony and savor moments of relaxation in a spacious living area and well-equipped kitchen.<br/> 
          Whether you seek quiet reflection or lively gatherings, this coastal escape offers a perfect blend of comfort and charm,<br/> 
          revealing the horizon's beauty at every turn.
        </p>
      </div>
      <div className="apartment-pictures">
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Apartment 3 ${index + 1}`}
              onClick={() => openModal(index)}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </Slider>
      </div>

      {/* Fullscreen Modal */}
      <Modal
        isOpen={isModalOpen}
        currentImage={currentImage}
        images={images}
        onClose={closeModal}
        onNext={goNext}
        onPrev={goPrev}
      />

      <div className="utilities">
        <div className="utilities-icon">
          <img src={NoSmoking} alt="No Smoking" />
          <p>No Smoking</p>
        </div>
        <div className="utilities-icon">
          <img src={WiFi} alt="WiFi" />
          <p>WiFi</p>
        </div>
        <div className="utilities-icon">
          <img src={Parking} alt="Parking" />
          <p>Parking</p>
        </div>
        <div className="utilities-icon">
          <img src={AC} alt="Air Conditioning" />
          <p>Air Conditioning</p>
        </div>
        <div className="utilities-icon">
          <img src={Kitchen} alt="Kitchen" />
          <p>Kitchen</p>
        </div>
        <div className="utilities-icon">
          <img src={Microwave} alt="Microwave" />
          <p>Microwave</p>
        </div>
        <div className="utilities-icon">
          <img src={Coffee} alt="Coffee Machine" />
          <p>Coffee Machine</p>
        </div>
        <div className="utilities-icon">
          <img src={TV} alt="TV" />
          <p>TV</p>
        </div>
        <div className="utilities-icon">
          <img src={DoubleBed} alt="Double Bed" />
          <p>Double Bed</p>
        </div>
      </div>
      {/* Price List Table */}
      <div className="price-list">
      <em><h1>Price List</h1></em>
        <table>
          <thead>
            <tr>
              <th>Date:</th>
              <th>Price per night:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>April 1 - June 31</td>
              <td>80€</td>
            </tr>
            <tr>
              <td>July 1 - July 14</td>
              <td>90€</td>
            </tr>
            <tr>
              <td>July 15 - August 25</td>
              <td>110€</td>
            </tr>
            <tr>
              <td>August 26 - September 14</td>
              <td>90€</td>
            </tr>
            <tr>
              <td>September 15 - November 1</td>
              <td>70€</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Apartment3;
