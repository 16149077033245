import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS for the Home page
import ScrollToSection from '../../components/ScrollToSection';

// Import images
import coverImage from '../../Pictures/cover/cover.jpg';
import selceImage from '../../Pictures/cover/Selce.jpg';
import Apartment1 from '../../Pictures/Apartments/Apartment 1/04-APP1.jpg';
import Apartment2 from '../../Pictures/Apartments/Apartment 2/08-APP2.jpg';
import Apartment3 from '../../Pictures/Apartments/Apartment 3/06-APP3.jpg';
import Apartment4 from '../../Pictures/Apartments/Apartment 4/06-APP4.jpg';

// Import react-slick
import Slider from 'react-slick';

// Slick Slider CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Reservation from '../../components/Reservation/Reservation';

const Home = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      });
    });

    // Target elements for observation
    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach(element => observer.observe(element));

    // Cleanup observer on component unmount
    return () => {
      elements.forEach(element => observer.unobserve(element));
    };
  }, []);

  // Slick slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20%',
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: '0%',
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0%',
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="home-page">
      <div className="cover-photo">
        <img src={coverImage} alt="Cover" id="background-cover-image" />
        <div className="cover-text">
          <h1>Apartmani Sunčica</h1>
          <div id="cover-subtext">
            <ScrollToSection id="apartments"><h2>Find Your Perfect Home</h2></ScrollToSection>
          </div>
        </div>
      </div>
      <div className="body-container">
        <div className="about animate-on-scroll">
          <h1><em>Discover Your Mediterranean Retreat</em></h1>
          <br />
          <p>
            Discover Apartmani Sunčica, where Selce’s charm meets modern luxury.<br />
            Nestled in this picturesque coastal town, our contemporary apartments offer an exquisite blend of comfort and elegance.<br />
            Just a short stroll from stunning beaches, vibrant markets, and delightful dining, our location invites you to explore and immerse yourself in local culture.<br />
            Experience the serene beauty of Selce with us - your perfect Mediterranean getaway awaits.
          </p>
          <br />
        </div>

        <div className="carousel animate-on-scroll" id="apartments">
          <Slider {...sliderSettings}>
            <div className="apartment">
              <div className="apartment-image">
                <img src={Apartment1} alt="Apartman 1" />
              </div>
              <div className="apartment-about">
                <h1>Apartman 1</h1>
                <h2>2 Bedrooms | 2 Bathrooms | 50 m<sup>2</sup></h2>
                <h2>Sunny Retreat</h2>
                <br />
                <Link to="/apartment1" className="apartment-button">&rarr; More</Link>
              </div>
            </div>
            <div className="apartment">
              <div className="apartment-image">
                <img src={Apartment2} alt="Apartman 2" />
              </div>
              <div className="apartment-about">
                <h1>Apartman 2</h1>
                <h2>1 Bedroom | 1 Bathroom | 35 m<sup>2</sup></h2>
                <h2>Seaside Charm</h2>
                <br />
                <Link to="/apartment2" className="apartment-button">&rarr; More</Link>
              </div>
            </div>
            <div className="apartment">
              <div className="apartment-image">
                <img src={Apartment3} alt="Apartman 3" />
              </div>
              <div className="apartment-about">
                <h1>Apartman 3</h1>
                <h2>1 Bedroom | 1 Bathroom | 48 m<sup>2</sup></h2>
                <h2>Cozy Corner</h2>
                <br />
                <Link to="/apartment3" className="apartment-button">&rarr; More</Link>
              </div>
            </div>
            <div className="apartment">
              <div className="apartment-image">
                <img src={Apartment4} alt="Apartman 4" />
              </div>
              <div className="apartment-about">
                <h1>Apartman 4</h1>
                <h2>2 Bedrooms | 2 Bathrooms | 72 m<sup>2</sup></h2>
                <h2>Elegant Getaway</h2>
                <br />
                <Link to="/apartment4" className="apartment-button">&rarr; More</Link>
              </div>
            </div>
          </Slider>
        </div>
        <div className="local-area animate-on-scroll" id='local-area'>
          <div className="local-area-image">
            <img src={selceImage} alt="Local Area" />
          </div>
          <div className="local-area-about">
            <h1><em>Discover Selce</em></h1>
            <br/>
            <p>
              Nestled along the pristine coastline of Croatia, Selce is a charming seaside town renowned for its crystal-clear waters, stunning beaches, and vibrant local culture.
              This picturesque destination offers a perfect blend of natural beauty and rich history, making it an ideal spot for both relaxation and adventure.
            </p>
            <br />
            <ul>
              <li><em>Beautiful Beaches:</em> Selce boasts some of the best beaches in the region, perfect for swimming, sunbathing, and water sports.</li>
              <li><em>Cultural Heritage:</em> Wander through the historic streets, visit the local churches, and experience the traditional Croatian architecture.</li>
              <li><em>Outdoor Activities:</em> Enjoy a variety of outdoor activities such as biking, hiking, and diving. The nearby hills and trails offer breathtaking views and exciting adventures.</li>
              <li><em>Local Cuisine:</em> Savor the flavors of local cuisine at the charming seaside restaurants and cafes, where you can taste fresh seafood and regional specialties.</li>
              <li><em>Festivals and Events:</em> Throughout the year, Selce hosts numerous festivals and events, celebrating its rich cultural heritage and vibrant community spirit.</li>
            </ul>
            <br />
          </div>
        </div>
      </div>
      <div className='reservation-form animate-on-scroll' id="reservation-form">
        <Reservation />
      </div>
      <div className='faq' id="faq">
      <h1><em>FAQs:</em></h1>
      <br/>
      <ul>
    <li><em>Check-In and Check-Out Times? </em> Check-in is from 2 PM and check-out is by 10 AM.</li>
    <li><em>Are Pets Allowed?</em> Unfortunately, pets are not allowed in the apartments.</li>
    <li><em>Meal Options?</em> We do not offer meals, but there are several dining options nearby.</li>
    <li><em>Parking Availability?</em> Free parking is available for guests on-site.</li>
    <li><em>Wi-Fi Access?</em> Complimentary Wi-Fi is provided throughout the property.</li>
    <li><em>Are there any additional charges?</em> There are no additional charges.</li>
    <li><em>Local Attractions?</em> Explore beautiful beaches, historic sites, and vibrant local markets.</li>
    <li><em>Payment Methods?</em> We accept cash only.</li>
</ul>
      </div>
    </div>
  );
};

export default Home;
