// src/components/GoToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function GoToTop() {
  const location = useLocation();

  useEffect(() => {
    // Immediately scroll to the top
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  return null;
}
