import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import './Reservation.css';

const Reservation = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [fadeClass, setFadeClass] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (executeRecaptcha) {
      setIsSubmitting(true);

      try {
        const token = await executeRecaptcha('submit');
        setRecaptchaToken(token);

        const formDataWithToken = {
          ...formData,
          recaptchaToken: token
        };

        const response = await fetch('/api/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formDataWithToken)
        });

        if (response.ok) {
          setFeedbackMessage('Email sent!');
        } else {
          setFeedbackMessage('Failed to send your message. Please try again later.');
        }
      } catch (error) {
        setFeedbackMessage('Error sending message. Please try again later.');
        console.error('Error:', error);
      } finally {
        setIsSubmitting(false);

        // Start fade-out after 1 second
        setTimeout(() => {
          setFadeClass('fade-out');
          setTimeout(() => {
            setFeedbackMessage('');
            setFadeClass('');
          }, 1000); // Clear message and fade class after fade-out
        }, 1000);
        
        // Clear form fields
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      }
    } else {
      setFeedbackMessage('reCAPTCHA is not available. Please try again later.');
      setIsSubmitting(false);
      setTimeout(() => {
        setFadeClass('fade-out');
        setTimeout(() => {
          setFeedbackMessage('');
          setFadeClass('');
        }, 1000); // Clear message and fade class after fade-out
      }, 1000);
    }
  };

  return (
    <div className="reservation-page">
      <div className="about">
        <h1><em>Make a Reservation</em></h1>
        <br />
        <p>Interested in booking your next getaway? <br/>
        Send us an email and any questions you have about availability or reservations for our apartments. <br/>
        We’re here to help make your stay as comfortable and enjoyable as possible. <br/>
        Simply fill out the form below, and we'll get back to you promptly to assist with your booking.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          required
          disabled={isSubmitting}
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
          disabled={isSubmitting}
        />
        <textarea
          id="message"
          name="message"
          rows="6"
          cols="30"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
          disabled={isSubmitting}
        />
        <button type="submit" disabled={isSubmitting}>Send Inquiry</button>
      </form>
      {feedbackMessage && (
        <div className={`feedback-message ${fadeClass}`}>
          {feedbackMessage}
        </div>
      )}
    </div>
  );
};

export default Reservation;
