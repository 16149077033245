import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * ScrollToSection component that handles smooth scrolling to a specific section.
 * @param {Object} props
 * @param {string} props.id - The ID of the section to scroll to.
 * @param {React.ReactNode} props.children - The content that triggers the scroll.
 * @param {number} [props.headerOffset=60] - The offset for the header height.
 * @param {Function} [props.onClick] - Optional onClick handler.
 * @returns {JSX.Element}
 */
const ScrollToSection = ({ id, children, headerOffset = 60, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();

    if (location.pathname === '/') {
      // On the home page
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    } else {
      // Navigate to home and scroll to section
      navigate('/', { replace: true });
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 500); // Delay to allow navigation to complete
    }

    // Call the onClick handler if provided
    if (onClick) {
      onClick();
    }
  };

  return (
    <a href={`#${id}`} onClick={handleClick}>
      {children}
    </a>
  );
};

export default ScrollToSection;
